<template>      
    <form @submit.prevent="checkForm" class="mb-4">
        <div class="row">
			<div class="col-12">
				<div class="form-group">
					<label for="payment_date">{{ $t('payment.payment_date') }} *</label>
					<e-datepicker v-model="payment_local.payment_date"></e-datepicker>
				</div>
			</div>
			<template v-if="payment.method.paymentmethod_code == 'CH'">
				<div class="col-12 col-sm-6">
					<div class="form-group">
						<label for="payment_bankcheck">{{ $t('payment.payment_bankcheck') }}</label>
						<input id="payment_bankcheck" class="form-control" v-model="payment_local.payment_bankcheck" :placeholder="this.getTrad('payment.payment_bankcheck_placeholder')">
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div class="form-group">
						<label for="payment_bank">{{ $t('payment.payment_bank') }}</label>
						<e-select
							id="payment_bank"
							v-model="payment_local.bank"
							:options="payment_banks"
							track-by="paymentbank_id"
							label="paymentbank_label"
							:allow-empty="false"
						/>
					</div>
				</div>
			</template>
			<div class="col-12">
				<div class="form-group">
					<label for="payment_comment">{{ $t('payment.payment_comment') }}</label>
					<textarea id="payment_comment" class="form-control" v-model="payment.payment_comment" :placeholder="this.getTrad('payment.payment_comment_placeholder')" rows="2"></textarea>
				</div>
			</div>
		</div>
    </form>
</template>

<script type="text/javascript">
	import Payment from "@/mixins/Payment.js"

	export default {
		name: "EditPayment",
		mixins: [Payment],
		props: {
			payment: {
				type: Object,
				default: null
			},
		},
		data () {
			return {
				payment_local: {},
				processing: false,
				payment_banks: this.getConfig('payment_bank'),
				errors: [],
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {
				this.payment_local = this.payment

				this.$emit('update:ready', true)
			},
			async checkForm() {

			},

			async editPayment() {
				this.errors = []

				if(!this.processing) {
					this.processing = true
				
					this.$emit('update:processing', true)


					if(!Date.parse(this.payment_local.payment_date)) {
						this.errors.push("Date")
					}

					if(this.errors.length > 0) {
						this.processing = false
						this.$emit('update:processing', false)
						return false
					}

					const params = {
						payment_date: this.payment_local.payment_date,
						payment_bankcheck: this.payment_local.payment_bankcheck,
						payment_bank: (this.payment_local.bank) ? this.payment_local.bank.paymentbank_id : null,
						payment_comment: this.payment_local.payment_comment,
					}

					const result = await this.saveEditPayment(this.payment_local.payment_id, params)

					if(result) {
						this.$emit('update:processing', false)
						this.processing = false
						this.successToast()
						return true
					}
					else {
						this.processing = false
						this.$emit('update:processing', false)
						return false
					}
				}
			},
		}
	}
</script>